import React from "react";
import Book from "../components/Book/Book.tsx";

const Book_Appointment = () => {
  return (
    <>
      <Book />
    </>
  );
};

export default Book_Appointment;

import React from "react";
import Contact from "../components/ContactUs/Contact.tsx";

const ContactUs = () => {
  return (
    <>
      <Contact />
    </>
  );
};
export default ContactUs;

import React from "react";
import Course from "../components/Courses/Course.tsx";

const Courses = () => {
  return (
    <>
      <Course />
    </>
  );
};
export default Courses;
